import moment from "moment"

const stringify = val => {
    try {
        switch (typeof val) {
            case 'boolean':
            case 'number':
                return String(val)
            case 'object':
                return JSON.stringify(val)
            default:
                return val
        }
    } catch(e) {
        console.error(e)
    }
}
const zeroPrefix = (value, totalLength) =>{
    let result = value ? value.toString() : '';
    while(result.length < totalLength) {
        result = '0'+result;
    }
    return result;
}
const parseTime = (hours, minutes, seconds) =>{
    let nSeconds = seconds === 60 ? 0 : seconds;
    let nMinutes = seconds === 60 ? minutes + 1 : minutes;
    let nHours = nMinutes === 60 ? hours + 1 : hours;
    return `${zeroPrefix(nHours,2)}:${zeroPrefix(nMinutes,2)}:${zeroPrefix(nSeconds,2)}`;
}

const parseValue = (value = '', fieldType, hfInstance, timezone, isParsingPayload) => {
    if (['object', 'boolean'].includes(typeof value) && !Array.isArray(value) && fieldType !== 'calculation-text') {
        return value
    }
    let parsedValue = String(value).replace(/^"|"$/g, '')
    const {date_format,datetime_format, toValidMoment} = timezone || {};
    switch (fieldType) {
        case 'number':
        case 'currency':
        case 'calculation-number':
        case 'calculation-currency':
            parsedValue = parsedValue ? Number(parsedValue) : 0
            break;
        case 'calculation-date':
        case 'calculation-date-time':
            let dateObj = null
            try {
                if (hfInstance && parsedValue) {
                    const hfDate = hfInstance.numberToDateTime(
                        Number(String(parsedValue))
                    )
                    if (
                        !isNaN(hfDate?.year) &&
                        !isNaN(hfDate?.month) &&
                        !isNaN(hfDate?.day)
                    ) {
                        dateObj = hfDate
                    }
                }
            } catch (e) {
                console.error(e)
            }
           // console.log('fieldType============',fieldType)
            const showTime = fieldType.includes("time");
            const valueFormat = showTime ? datetime_format : date_format;
            const {year, month, day, hours, minutes, seconds} = dateObj || {};  
            const parsedTime = parseTime(hours, minutes, seconds);
            const dateTimeString = `${zeroPrefix(year,4)}-${zeroPrefix(month,2)}-${zeroPrefix(day,2)}`+ 
            (showTime ? ` ${parsedTime}`:'');
            if (dateObj) {
                parsedValue = toValidMoment(dateTimeString).format(valueFormat)
            } else {
                if (parsedValue && parsedValue.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)) { // match in example 2023-09-11T00:00:00 string date value
                    const timezone = localStorage.getItem('fsa-time-zone') ? JSON.parse(localStorage.getItem('fsa-time-zone')) : { date_format: 'YYYY-MM-DD', datetime_format: 'YYYY-MM-DD HH:mm:ss' };
                    const displayFormat = showTime ? timezone.datetime_format : timezone.date_format;
                    parsedValue = moment(parsedValue).format(displayFormat);
                }else{
                    parsedValue = parsedValue || ''
                }
            }
            break;
        case 'date':
            if(isParsingPayload){
                if(parsedValue && toValidMoment){
                    parsedValue = toValidMoment(parsedValue).utc(true).toISOString();
                } else {
                    parsedValue = ![undefined, null].includes(parsedValue) ? stringify(parsedValue) : ''
                }
            }
            break;
        default:
            parsedValue = ![undefined, null].includes(parsedValue) ? stringify(parsedValue) : ''
            break;
    }
    return parsedValue
}

export default parseValue